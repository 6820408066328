<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1 class="text-uppercase">{{ translations.tcInternationalScriptureBlitzParticipants }}</h1>
    </header>
    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <data-table :fields="fields" :listType="list_type" :i18n="translations.components"
      :items="scriptureBlitzSpeakersAlter" :addItems="addItems" :toggleMe="true" :handleDeleteAction="false"
      :handleEditAction="false" :handleViewAction="false" :csvUrl="csvUrl" :pdfUrl="pdfUrl"
      :reportName="reportName"></data-table>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMemberChurches.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'scripture-blitz-speakers',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      list_type: 'camp_member_churches',
      fields: [
        { key: 'sort_name', label: 'Name', sortable: true },
        { key: 'ixa_scripture_blitz_title', label: 'Country', sortable: false },
        {
          key: 'ixa_scripture_blitz_date',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            let visit_date = new Date(value)
            let vd = [
              visit_date.getFullYear(),
              (visit_date.getMonth() + 1 < 10 ? '0' : '') + (visit_date.getMonth() + 1),
              (visit_date.getDate() < 10 ? '0' : '') + visit_date.getDate(),
            ]
            return vd.join('-')
          },
        },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Speaker', // Add Visit
        action: 'addPresenter',
      },
      reportName: 'ScriptureBlitzParticipantsExport',
    }
  },
  methods: {
    ...mapActions({
      getScriptureBlitzSpeakers: 'directory/getScriptureBlitzSpeakers',
      getMemberChurches: 'campModule/getMemberChurches',
      loadChurches: 'churchMinistry/loadChurches',
      loadScriptureBlitzSpeakersUrls: 'directory/loadScriptureBlitzSpeakersUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await this.getScriptureBlitzSpeakers(this.userStateKey)
        await this.loadScriptureBlitzSpeakersUrls(this.userStateKey)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddMember
      this.fields[0].label = this.translations.tcName
      this.fields[1].label = this.translations.tcCountry
      this.fields[2].label = this.translations.tcDate
    },
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('camp-select', 'data-table', 'data-table-member-churches'),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.loadTranslations()
    })
  },
  computed: {
    ...mapGetters({
      csvUrl: 'directory/scriptureBlitzSpeakersCSVUrl',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      pdfUrl: 'card/restockingVisitPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      scriptureBlitzSpeakers: 'directory/scriptureBlitzSpeakers',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userStateKey: 'user/userStateKey',
    }),
    scriptureBlitzSpeakersAlter() {
      let scripture = this.scriptureBlitzSpeakers.map((x) => {
        return {
          ixa_scripture_blitz_date: x.ixa_scripture_blitz_date,
          vin_ind_key: x.ind_key,
          ixa_scripture_blitz_title: x.ixa_scripture_blitz_title,
          name: x.name,
          sort_name: x.sort_name,
        }
      })
      return scripture
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
